import React from "react";
import qualityServiceImg from "../../images/neha_photo.jpg";
import eventbg from "../../images/event-bg.png";

const QualityService = () => {
  return (
    
    <div
      
      style={{
        backgroundImage: `url(${eventbg})`,
        
      }}
    >
      <div className="container lg:flex py-16">
        <div className="h-full lg:w-2/3 sm:w-1/3  ">
          <img className="w-full rounded-lg " src={qualityServiceImg} alt="" />
        </div>
        <div className="lg:ml-8">
          <h1 className="text-3xl font-Poppins font-semibold ">
            Meet Our 
            <span className="text-primary"> Dentist</span>
          </h1>
          <h3 className=" font-Poppins font-semibold " >DR. NEHA AGGARWAL</h3>
          <p>BDS, MDS, Posthodontist & Implantologist</p>
          <p className="py-8 font-semibold">
          <p>To be committed to excellence in all of our services to our patients so that we may help them achieve the highest level of dental health appropriate for their needs in an atmosphere of trust and compassion.</p>
          <br/>
          <p>We at Xpertsmiles treat the patient's dental needs with the highest level of care, skill, judgment and comfort.</p>
          <br/>
          <p>We will appreciate, communicate and empathize with our patients and our fellow team members so that we may enhance the lives of every person involved in achieving our mission.</p>{" "}
          </p>
          <div className="lg:flex justify-between">
            <ul>
              <li className="flex">
                <i className="far fa-hand-point-right text-3xl text-green-500"></i>
                <h1 className="text-lg pb-8 px-4 uppercase font-bold leading-snug text-black hover:opacity-75">
                  Trained Staff
                </h1>
              </li>
              <li className="flex">
                <i className="far fa-hand-point-right text-3xl text-green-500"></i>
                <h1 className="text-lg pb-8 px-4 uppercase font-bold leading-snug text-black hover:opacity-75">
                  We provide quality Treatment
                </h1>
              </li>
              <li className="flex">
                <i className="far fa-hand-point-right text-3xl text-green-500"></i>
                <h1 className="text-lg pb-8 px-4 uppercase font-bold leading-snug text-black hover:opacity-75">
                  Best Price
                </h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    

  );
};

export default QualityService;
