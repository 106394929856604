import React from "react";
import { Link } from "react-router-dom";
import banner from "../../images/banner.png";
import stamp from "../../images/stamp.png";

const Banner = () => {
  return (
    <div
      className="bg-cover bg-no-repeat bg-center py-5"
      style={{
        backgroundImage: `url(${banner})`,
        
      }}
    >
      <div className="container">
        <h1 className="text-5xl py-6 font-Poppins font-semibold ">
        <h1 >XpertSmiles Offer.! </h1> <h1 className="text-primary py-2" >keep Smiling.</h1>
        </h1>
        <p className="w-50 font-semibold">{" "}
          
          Get your Teeth Cleaning & Polishing done for just Rs.500/-.
        </p>
        
        <img src={stamp} alt="Get Your Now" width={"200"} height={"200"}/>
        
        <div className="mt-8 ">
        
          <Link
            to="/form"
            className="bg-primary border border-primary text-white px-3 py-2 font-medium rounded hover:bg-transparent hover:text-primary transition"
          >
            Make an Appointment
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
