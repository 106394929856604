import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Initialize Firebase
const app = firebase.initializeApp({
  apiKey: "AIzaSyALSjiSuIFszV4CkwJz28GYPD1Rm07q0rk",
  authDomain: "care-clinic-7225e.firebaseapp.com",
  projectId: "care-clinic-7225e",
  storageBucket: "care-clinic-7225e.appspot.com",
  messagingSenderId: "858962530820",
  appId: "1:858962530820:web:496f60792f15f5760ddb51",
});

export const auth = app.auth()
export default app