import React, { useState } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";



const Form = () => {
   const[userData, setUserData] = useState({
      fullName: "",
      phoneNo: "",
      email: ""

   }); 
   let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };

  // connect with firebase
  const submitData = async (event) => {
    event.preventDefault();
    const { fullName, phoneNo, email} = userData;

    if (fullName  && phoneNo && email ) {
      const res = fetch(
        "https://care-clinic-7225e-default-rtdb.firebaseio.com/userDataRecords.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName,
            phoneNo,
            email
          }),
        }
      );

      if (res) {
        setUserData({
          fullName: "",
          phoneNo: "",
          email: "",
         
        });
        alert("hey, we got your information. we will contact you soon. Get ready for Exciting offers!!!!");
      } else {
        alert("plz fill the data");
      }
    } else {
      alert("plz fill the data");
    }
  

   

   };
  return (
        <div className="text-centre">
        <Breadcrumbs title="Best Dental Clinic in Rohini" />
        <div className="lg:ml-8 text-center	py-5">
          <h1 className="text-3xl font-Poppins font-semibold ">Get your Free
        <span className="text-primary"> Consultation </span>
        </h1>
        </div>

       
        <div className="grid lg:grid-cols-1 grid-cols-1 lg:gap-6 gap-2 justify-between  place-items-center">
          <div className="">
            <div className="">
              <fieldset>
                <label for="fname">Full Name:</label>
                <input
                  className="border-opacity-100 w-full border px-4 border-gray-800 py-2"
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={userData.fullName}
                  onChange={postUserData}
                  
                />
                <br />
                <br />
                <label for="phoneNo">Phone No. :</label>
                <input
                  className="border-opacity-100 w-full border px-4 border-gray-800 py-2"
                  type="text"
                  id="phoneNo"
                  name="phoneNo"
                  value={userData.phoneNo}
                  onChange={postUserData}
                  
                />
                <br />
                <br />
                <label for="email">Email:</label>
                <input
                  className="border-opacity-100 w-full border px-4 border-gray-800 py-2"
                  type="text"
                  id="email"
                  name="email"
                  value={userData.email}
                  onChange={postUserData}
                  
                />
                <br />
                <button type='submit' className="bg-white mt-4 border border-primary text-black py-3 px-6 rounded-md hover:bg-transparent hover:bg-primary hover:text-white transition" onClick={submitData}>
                  Submit
                </button>
              </fieldset>
            </div>
          </div>
          </div>
        </div>
  );
};
export default Form;